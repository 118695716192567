import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import { useAuth } from "./../util/auth";
import { useRouter } from "./../util/router.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { createItem } from "./../util/db";
import { modifiedApiRequest } from "../util/util";
import { CheckBoxOutlined } from "@material-ui/icons";
import PromptWarning from "./PromptWarning.js";
import { OpenAI } from "openai";
import TimerLoader from "./TimerLoader.js";
import { Download } from "lucide-react";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

Array.prototype.sample = function () {
  return this[Math.floor(Math.random() * this.length)];
};

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const generateSeed = () => {
  let randomBytes = new Uint8Array(2);
  window.crypto.getRandomValues(randomBytes);

  const seed = (randomBytes[0] << 8) | randomBytes[1];
  return seed;
};

const useStyles = makeStyles((theme) => ({
  media: {
    height: 160,
  },
}));

function Generator(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [prediction, setPrediction] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [adjustedUserPrompt, setAdjustedUserPrompt] = useState("");
  const [adjustedUserNegativePrompt, setAdjustedUserNeagtivePrompt] =
    useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);

  const [inputData, setInputData] = useState({
    prompt: router.query.prompt || "beautiful asian girl",
    negativePrompt: router.query.negativePrompt || "",
    style: router.query.style || "real",
    pose: router.query.pose || "Default",
    isPrivate: false,
  });

  async function promptChecker(prompt) {
    try {
      const response = await openai.chat.completions.create({
        model: "gpt-4o",
        messages: [
          {
            role: "system",
            content: `
              Strictly analyze if the prompt contains any of the following:
              - Explicit language for child exploitation
              - Celebrity mentions, names, or identifying details
              - Religious references or discussions
              Allow all the explicit contents like any sexual words like dick, boobs, sex etc.
              Respond with 'YES' if any such content is detected, or 'NO' if the prompt is safe.
            `,
          },
          { role: "user", content: prompt },
        ],
        temperature: 1,
        max_tokens: 10,
      });

      return response.choices[0].message.content.trim().toUpperCase();
    } catch (error) {
      console.error(`Prompt checking error: ${error.message}`);
      throw new Error("Failed to validate prompt safety");
    }
  }

  const { prompt, negativePrompt, style, pose, isPrivate } = inputData;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleStyleClick = (style) => {
    setInputData((prevData) => ({ ...prevData, style: style }));
  };

  const handleSwitch = (event) => {
    if (
      auth.user &&
      auth.user.planIsActive &&
      auth.user.stripeSubscriptionStatus === "active"
    ) {
      setInputData((prevData) => ({
        ...prevData,
        [event.target.name]: event.target.checked,
      }));
    } else {
      alert("Upgrade to pro to use this feature");
    }
  };

  const modelStyles = [
    {
      image: "https://imgur.com/5UkqSlq.png",
      value: "real",
      text: "Real",
      goodPrompt:
        "RAW photo, SUBJECT, 8k uhd, dslr, soft lighting, high quality, film grain, Fujifilm XT3",
      badPrompt:
        "(easynegative, ng_deepnegative_v1_75t, SUBJECT, deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime), text, cropped, out of frame, worst quality, low quality, jpeg artifacts, ugly, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, dehydrated, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, UnrealisticDream",
    },
    {
      image: "https://imgur.com/T6oGtZf.png",
      value: "hyperreal",
      text: "Hyper Real",
      goodPrompt:
        "((photo, high quality, high resolution, 4k, hd, hdr, dslr)), 1girl, SUBJECT",
      badPrompt:
        "easynegative, ng_deepnegative_v1_75t, SUBJECT, paintings, sketches, digital arts, (worst quality:2), (low quality:2), (normal quality:2), low resolution, ((monochrome)), ((grayscale)), watermark, out of frame, (ugly:1.2), people",
    },
    {
      image: "https://i.imgur.com/Ya2D8Jm.png",
      value: "anime",
      text: "Anime",
      goodPrompt: "masterpiece, best quality, 1girl, SUBJECT",
      badPrompt:
        "easynegative, ng_deepnegative_v1_75t, SUBJECT, (low quality, worst quality:1.4), lowres, bad anatomy, bad hands, normal quality, ((monochrome)), ((grayscale))",
    },
  ];

  const modelPoses = [
    "Default",
    "POV BlowJob",
    "POV DoggyStyle",
    "HandJob",
    "Flashing Boobs",
    "Stick Out Tongue",
    "Grab Ass",
    "POV Anal",
    "Vagina Spread",
    "Female Masturbation",
    "TitJob",
    "Bukkake",
    "Spooning",
    "POV Missionary",
    "Cheek Bulge BlowJob",
    "Solo Breast Grab",
    "Resting on Stomach",
    "BlowJob Under Desk",
    "Front View CowGirl",
    "POV SpitRoast",
    "POV Reverse CowGirl",
  ];

  const PROMPTS = [];

  const wordList = {
    abuse: "party",
    "acid attack": "adult",
    adolescent: "adult",
    babyish: "adult",
    baby: "young",
    "black market": "adult",
    bullying: "adult",
    callow: "adult",
    child: "adult",
    little: "adult",
    childish: "adult",
    Child: "adult",
    childlike: "adult",
    children: "adult",
    contraband: "adult",
    "criminal behavior": "adult",
    embryonic: "adult",
    ephebic: "adult",
    exploitation: "adult",
    felony: "adult",
    flowering: "adult",
    forced: "adult",
    fraud: "adult",
    gore: "dancing",
    harassment: "adult",
    trafficking: "adult",
    illegal: "adult",
    illicit: "adult",
    immature: "adult",
    assault: "adult",
    infanticide: "adult",
    infantile: "adult",
    infantine: "adult",
    juvenile: "adult",
    kiddish: "adult",
    lawbreaking: "adult",
    molestation: "adult",
    "money laundering": "adult",
    petite: "adult",
    kid: "adult",
    small: "size",
    preteen: "adult",
    preteens: "adult",
    prostitution: "adult",
    pubescent: "adult",
    puerile: "adult",
    rape: "adult",
    raping: "adult",
    ravishment: "adult",
    raw: "adult",
    school: "office",
    assault: "playing",
    harassment: "playing",
    stalking: "adult",
    teen: "adult",
    teenage: "adult",
    teenaged: "adult",
    teens: "adult",
    "under 18": "21+",
    underage: "adult",
    unethical: "adult",
    unfledged: "adult",
    unlawful: "adult",
    unripe: "adult",
    unripened: "adult",
    wrongful: "adult",
    youngish: "adult",
    young: "adult",
    Pre: "",
    Very: "",
    kindergarten: "",
    kinder: "",
  };

  const generate = async (_prompt, _pose, _style) => {
    try {
      setLoaderOpen(true);
      setPending(true);
      const seed = generateSeed();
      const adjustedNegativePrompt = processPromptWithWordList(
        negativePrompt,
        wordList
      );

      if (negativePrompt !== adjustedNegativePrompt) {
        setAdjustedUserNeagtivePrompt(adjustedNegativePrompt);
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }

      // const isSafe = await promptChecker(prompt);
      const isSafe = "NO";
      if (isSafe === "YES") {
        setShowWarning(true);
        setPending(false);
        return;
      }

      const inputData = {
        prompt: prompt,
        negativePrompt: adjustedNegativePrompt,
        pose: _pose || pose,
        style: _style || style,
        seed: seed,
      };

      // console.log(inputData, "Input data for generation");

      let prediction = await modifiedApiRequest(
        "modified-predictions",
        "POST",
        inputData
      );
      // console.log(prediction, "preeeeeeeeeeeddddddddddddd");
      setPrediction(prediction);
      setPending(false);

      if (prediction.status === "COMPLETED") {
        createItem({
          owner: auth.user.uid,
          ownerName: auth.user.displayName,
          userPrompt: _prompt || prompt,
          userNegativePrompt: adjustedNegativePrompt,
          style: _style || style,
          pose: _pose || pose,
          isPrivate: isPrivate,
          isNSFW: true,
          url: prediction.output[0].url,
        });
      }

      // Add further API call or logic here for generation
    } catch (error) {
      setPending(false);
      console.error("Error during generation:", error.message);
    } finally {
      setLoaderOpen(false);
    }
  };

  const processPromptWithWordList = (prompt, wordList) => {
    let newText = String(prompt); // Ensure newText is a string
    for (const term in wordList) {
      const replacement = wordList[term];
      const escapedTerm = term.replace(/ /g, "\\s*"); // Replace spaces with regex for zero or more spaces
      const regex = new RegExp(escapedTerm, "gi"); // Match the term or phrase with spaces, case-insensitive
      newText = newText.replace(regex, String(replacement)); // Ensure replacement is a string
    }
    newText = newText.replace(/(\d{2}|[1-9])/g, (match) => {
      if (match.length === 2) {
        return "20+ ";
      } else if (match.length === 1) {
        return "20+ ";
      }
    });
    return newText;
  };
  const handleGenerate = () => {
    if (auth?.user?.credit < 0) {
      alert("Not enough credits");
      return;
    }
    generate();
  };

  const handleDownload = () => {
    const downloadUrl = `/api/download?url=${encodeURIComponent(
      prediction?.output[0]?.url
    )}`;
    window.open(downloadUrl, "_blank");
  };

  return (
    <>
      <form>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <TextField
              name="prompt"
              variant="outlined"
              type="string"
              label="Prompt"
              value={prompt}
              onChange={handleChange}
              fullWidth={true}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name="negativePrompt"
              variant="outlined"
              type="string"
              label="Negative Prompt"
              placeholder="ugly, hat, earring"
              value={negativePrompt}
              onChange={handleChange}
              fullWidth={true}
            />
          </Grid>

          <Box clone mt={1} mb={3}>
            <Container>
              <Grid container={true} spacing={4} justifyContent="center">
                {modelStyles.map((item, index) => (
                  <Grid item={true} xs={6} md={3} key={index}>
                    <Card>
                      <CardActionArea
                        onClick={() => handleStyleClick(item.value)}
                      >
                        <CardMedia
                          style={{ height: 130 }}
                          image={item.image}
                          className={classes.media}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            bgcolor:
                              item.value === style
                                ? "primary.dark"
                                : "rgba(0, 0, 0, 0.54)",
                            color: "white",
                            padding: "5px",
                          }}
                        >
                          <Typography variant="caption">{item.text}</Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>

          <Grid
            item={true}
            xs={12}
            container={true}
            spacing={4}
            justifyContent="space-between"
          >
            <Grid item={true}>
              <FormControl variant="outlined">
                <InputLabel id="style-label">Style</InputLabel>
                <Select
                  name="style"
                  onChange={handleChange}
                  value={style}
                  labelId="style-label"
                  id="style-select"
                  style={{ width: 240 }}
                >
                  {modelStyles.map((item, index) => (
                    <MenuItem value={item.value}>{item.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true}>
              <FormControl variant="outlined">
                <InputLabel id="pose-label">Pose</InputLabel>
                <Select
                  name="pose"
                  onChange={handleChange}
                  value={pose}
                  labelId="pose-label"
                  id="pose-select"
                  style={{ width: 240 }}
                >
                  {modelPoses.map((item, index) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isPrivate}
                  onChange={handleSwitch}
                  name="isPrivate"
                  color="primary"
                />
              }
              label="Keep image private?"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleGenerate}
              disabled={pending}
              fullWidth={true}
            >
              {!pending && <span>{props.buttonAction}</span>}

              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              borderRadius: "10px",
              padding: "1.25rem 1.25rem",
              border: "1px solid #1595a9",
              color: "#117b8c",
              width: "100%",
              background: "rgba(23, 162, 184, 0.2)",
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
              <CheckBoxOutlined
                style={{ color: "#117b8c", fontSize: "12px" }}
              />{" "}
              Unless an age is indicated, the default age for the prompt will be
              25 years old.
            </Typography>
            <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
              <CheckBoxOutlined
                style={{ color: "#117b8c", fontSize: "12px" }}
              />{" "}
              Images depicting violence or gore are prohibited.
            </Typography>
            <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
              <CheckBoxOutlined
                style={{ color: "#117b8c", fontSize: "12px" }}
              />{" "}
              Please refrain from using celebrity names. Any images resembling
              celebrities will be deleted.
            </Typography>
            <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
              <CheckBoxOutlined
                style={{ color: "#117b8c", fontSize: "12px" }}
              />{" "}
              Images involving minors will not be produced.
            </Typography>
          </Box>
          {showWarning && (
            <PromptWarning
              prompt={prompt}
              improvedPrompt={adjustedUserPrompt}
              negativePrompt={negativePrompt}
              improvedNegativePrompt={adjustedUserNegativePrompt}
            />
          )}

          <Grid
            item
            xs={12}
            justifyContent="center"
            style={{ position: "relative" }}
          >
            {prediction && prediction.status === "COMPLETED" && (
              <>
                <div className="relative">
                  <img
                    src={prediction.output[0].url}
                    width="100%"
                    alt="Generated"
                  />
                  <Button
                    onClick={handleDownload}
                    className="absolute top-[6px] right-[8px] rounded-full w-10 h-10 p-0 bg-white/80 hover:bg-white/90"
                    style={{ position: "absolute", top: "6px", right: "8px" }}
                    aria-label="Download image"
                  >
                    <Download className="h-5 w-5 text-gray-700 " />
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </form>
      <TimerLoader open={loaderOpen} onClose={() => setLoaderOpen(false)} />
    </>
  );
}

export default Generator;
