import React, { useState, useEffect } from "react";
import { Modal, Typography, CircularProgress, Box } from "@material-ui/core";

const TimerLoader = ({ open, onClose }) => {
  const [timeLeft, setTimeLeft] = useState(25);

  useEffect(() => {
    if (open) {
      setTimeLeft(25); // Reset timer to 20 seconds when opened
    }
  }, [open]);

  useEffect(() => {
    if (open && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [open, timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      onClose(); // Close the modal when the timer reaches zero
    }
  }, [timeLeft, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(8px)", // Adds a blurred background
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="rgba(255, 255, 255, 0.9)" // Slightly transparent white
        borderRadius={8}
        boxShadow={3}
        p={4}
        style={{
          width: "300px", // Width for the modal box
          textAlign: "center", // Center text
        }}
      >
        <CircularProgress size={80} />
        <Typography variant="h6" style={{ marginTop: 20, color: "black" }}>
          Image will be generated in {timeLeft} seconds
        </Typography>
      </Box>
    </Modal>
  );
};

export default TimerLoader;
