import React from "react";

function PaySuccessPage() {
  return (
    <>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase.</p>
        <p>Your order will be processed shortly.</p>
        <button
          style={{
            cursor: "pointer",
            padding: "20px",
            borderRadius: "20px",
            border: "1px solid black",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go to Home Page
        </button>
      </div>
    </>
  );
}
export default PaySuccessPage;
