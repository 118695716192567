import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
  useInfiniteQuery,
  useMutation,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  increment,
  decrement,
  addDoc,
  deleteDoc,
  serverTimestamp,
  runTransaction,
  arrayRemove,
  arrayUnion,
  documentId,
} from "firebase/firestore";
import { firebaseApp } from "./firebase";
import { apiRequest } from "./util";
import featuredImages from "./featuredImages";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

// Initialize Firestore
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

// models
export async function getAIModels() {
  try {
    // Reference to the 'ai_models' collection
    const modelsCollection = collection(db, "ai_models");

    // Fetch documents from the collection
    const querySnapshot = await getDocs(modelsCollection);

    // Map through the documents and return an array of model data
    const models = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return models;
  } catch (error) {
    console.error("Error fetching AI models:", error);
    throw error; // Rethrow the error for further handling if needed
  }
}

/**** STORAGE ****/
export async function uploadImageFromURL(imageUrl, storagePath) {
  try {
    // Fetch the image from the URL
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // Upload the blob to Firebase Storage
    const storageRef = ref(storage, storagePath);
    const uploadTask = uploadBytes(storageRef, blob);

    // Wait for the upload to complete
    await uploadTask;

    // Get the download URL of the uploaded image
    const downloadURL = await getDownloadURL(storageRef);

    console.log("Image uploaded and URL obtained:", downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error fetching, uploading, or obtaining URL:", error);
    throw error;
  }
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

export function likeItem(itemId) {
  return apiRequest("like", "POST", { itemId: itemId });
}

export function useItemsMutation(func) {
  return useMutation(func, {
    onSuccess: () => {
      client.invalidateQueries("items");
    },
  });
}

// export async function toggleLikeFirestore(itemId, user) {
//   const userId = user?.uid;

//   if (!userId) {
//     return; // User not authenticated
//   }

//   const itemRef = doc(db, 'items', itemId);
//   const userRef = doc(db, 'users', userId);

//   try {
//     await runTransaction(db, async (transaction) => {
//       const itemDoc = await getDoc(itemRef);

//       if (!itemDoc.exists()) {
//         return; // Item not found
//       }

//       const userLikes = user.likes || [];

//       if (userLikes.includes(itemId)) {
//         // User already liked this item, so unlike it
//         transaction.update(itemRef, { likesCount: (itemDoc.data().likesCount || 1) - 1 });
//         transaction.update(userRef, { likes: arrayRemove(itemId) });
//       } else {
//         // User hasn't liked this item, so like it
//         transaction.update(itemRef, { likesCount: (itemDoc.data().likesCount || 0) + 1 });
//         transaction.update(userRef, { likes: arrayUnion(itemId) });
//       }
//     });
//   } catch (error) {
//     console.error(error);
//   }
// }

// export function useToggleLike(user) {

//   return useMutation((itemId) => toggleLikeFirestore(itemId, user), {
//     // Invalidate the item query after a successful toggle
//     onSuccess: () => {
//       client.invalidateQueries('items');
//       client.invalidateQueries(['users', user.uId]);
//     },
//   });
// }

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

function fetchPagedItems(owner, isSubscriber) {
  return async ({ pageParam = new Date() }) => {
    let q = query(
      collection(db, "items"),
      where(documentId(), "in", featuredImages)
    );
    if (isSubscriber) {
      q = query(
        collection(db, "items"),
        where("isPrivate", "==", false),
        orderBy("createdAt", "desc"),
        startAfter(pageParam),
        limit(6)
      );
    } else {
    }
    if (owner && !Array.isArray(owner)) {
      q = query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc"),
        startAfter(pageParam),
        limit(6)
      );
    }
    const querySnapshot = await getDocs(q);

    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return {
      items,
      nextCursor:
        querySnapshot.docs.length === 0
          ? null
          : querySnapshot.docs[querySnapshot.docs.length - 1],
    };
  };
}

export function usePagedItemsByOwner(owner) {
  return useInfiniteQuery(["items", owner], fetchPagedItems(owner), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

function fetchPagedItemsLiked(likes) {
  return async ({ pageParam = 0 }) => {
    const items = [];
    for (
      let i = pageParam;
      i < Math.min(pageParam + 6, likes?.length || 0);
      i++
    ) {
      const itemId = likes[i];
      const documentRef = doc(db, "items", itemId);
      const item = await getDoc(documentRef).then(format);
      if (item) {
        items.push(item);
      }
    }

    return {
      items,
      nextCursor: items.length === 0 ? null : pageParam + 6,
    };
  };
}

export function usePagedItemsLiked(likes) {
  return useInfiniteQuery(["items", likes], fetchPagedItemsLiked(likes), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

export function usePagedItems(isSubscriber = false) {
  return useInfiniteQuery("items", fetchPagedItems(null, isSubscriber), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

// export async function itemsFirstBatch() {
//   // try {
//   //   const data = await db
//   //     .collection("items")
//   //     .orderBy("createdAt", "desc")
//   //     .limit(5)
//   //     .get();

//   //   let items = [];
//   //   let lastKey = "";
//   //   data.forEach((doc) => {
//   //     items.push({
//   //       id: doc.id,
//   //       ...doc.data()
//   //     });
//   //     lastKey = doc.data().createdAt;
//   //   });

//   //   return { items, lastKey };
//   // } catch (e) {
//   //   console.log(e);
//   // }
// }

/**
 * this function will be fired each time the user click on 'More Posts' button,
 * it receive key of last post in previous batch, then fetch next 5 posts
 * starting after last fetched post.
 */
// export async function itemsNextBatch(key) {
//   try {
//     const data = await db
//       .collection("items")
//       .orderBy("createdAt", "desc")
//       .startAfter(key)
//       .limit(5)
//       .get();

//     let items = [];
//     let lastKey = "";
//     data.forEach((doc) => {
//       items.push({
//         id: doc.id,
//         ...doc.data()
//       });
//       lastKey = doc.data().createdAt;
//     });
//     return { items, lastKey };
//   } catch (e) {
//     console.log(e);
//   }
// }

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
