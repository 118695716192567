import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import { likeItem, deleteItem, useItemsMutation } from "./../util/db";
import FavoriteIcon from "@material-ui/icons/Favorite";
import StyleIcon from "@material-ui/icons/Style";
import ColorizeIcon from "@material-ui/icons/Colorize";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopy";
import LockIcon from "@material-ui/icons/Lock";
import { useRouter } from "./../util/router.js";
import ItemModal from "./ItemModal";
import { DownloadIcon } from "lucide-react";

function useInfiniteScroll(fetchNextPage, hasNextPage, data, router) {
  const auth = useAuth();
  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + window.scrollY >=
          document.body.scrollHeight - 100 &&
        hasNextPage
      ) {
        if (!auth?.user?.planIsActive) {
          if (!auth?.user) {
            alert("Sign in to view more images");
            router.push("/auth/signin");
          } else if (auth.user && !auth.user.planIsActive) {
            alert("Subscribe in to view more images");
            router.push("/pricing");
          }
        } else {
          fetchNextPage();
        }
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchNextPage, hasNextPage, auth, data]);
}

const useStyles = makeStyles((theme) => ({
  media: {
    minHeight: 400,
  },

  deleteButton: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 100,
  },

  downloadButton: {
    position: "absolute",
    top: 10,
    zIndex: 100,
  },

  copyIcon: {
    marginRight: 5,
    fontSize: 10,
    padding: 0,
    display: "none",
  },

  blurOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
    color: "white",
    display: "flex", // Use flex display
    flexDirection: "column", // Arrange children vertically
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(10px)",
  },

  cardSubtitle: {
    display: "none",
  },

  cardContent: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
    color: "white",
    padding: "5px",
    paddingTop: "auto",
    paddingBottom: "5px",
    display: "flex", // Use flex display
    flexDirection: "column", // Arrange children vertically
    justifyContent: "flex-end",

    background: "transparent", // Linear gradient transparency on hover
    // backgroundColor: 'transparent', // Transparent background initially
    transition: "background 0.3s ease-in-out", // Add transition for smooth effect
    "&:hover": {
      background:
        "linear-gradient(to top, rgba(0, 0, 0, 0.64) 25%, transparent)",
      "& $cardSubtitle": {
        display: "block",
      },
      "& $copyIcon": {
        display: "inline-flex",
      },
    },
  },

  cardActions: {
    marginTop: 10,
    marginBottom: 2,
    paddingBottom: 0,
    paddingTop: 0,
    height: 20,
    display: "flex", // Use flex display
    flexDirection: "row", // Arrange children vertically
    justifyContent: "space-between",
    "& button": {
      padding: "4px 0px",
      color: "white",
    },
    "& span": {
      fontSize: 12,
    },
  },
}));

const truncate = (str, maxLength) =>
  str.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str;

function Gallery(props) {
  const auth = useAuth();
  const router = useRouter();
  const classes = useStyles();
  const { data, fetchNextPage, hasNextPage, isLoading, isError } = props;

  const [viewingItem, setViewingItem] = useState(false);
  const [viewingItemData, setViewingItemData] = useState({});

  const likeMutation = useItemsMutation(likeItem);
  const [likes, setLikes] = useState({});
  const [initLikes, setInitLikes] = useState({});

  const handleLike = (itemId) => {
    if (!auth.user) {
      alert("Sign in to like this image");
      return;
    }
    setLikes((prevState) => {
      return { ...prevState, [itemId]: !prevState[itemId] };
    });
    likeMutation.mutate(itemId);
  };
  const deleteMutation = useItemsMutation(deleteItem);

  const handleDelete = (itemId) => {
    deleteMutation.mutate(itemId);
    setViewingItem(false);
  };

  const handleClone = (item) => {
    router.push(
      `/create?style=${item.style}&pose=${item.pose}&prompt=${item.userPrompt}&negativePrompt=${item.userNegativePrompt}`
    );
  };

  const handleClick = (item) => {
    router.push("/images/" + item.id);
    // setViewingItemData(item)
    // setViewingItem(true)
  };

  const handleDownload = (url) => {
    const downloadUrl = `/api/download?url=${encodeURIComponent(url)}`;
    window.open(downloadUrl, "_blank");
  };

  useEffect(() => {
    if (
      auth.user &&
      Array.isArray(auth.user.likes) &&
      Object.keys(initLikes).length === 0
    ) {
      let newLikes = {};
      for (const itemId of auth.user.likes) {
        newLikes[itemId] = true;
      }
      setInitLikes(newLikes);
      setLikes(newLikes);
    }
  }, [auth]);

  useInfiniteScroll(fetchNextPage, hasNextPage, data, router);

  return (
    <Container>
      <Grid container={true} justifyContent="center" spacing={4}>
        {!isLoading &&
          data &&
          data.pages.map((page) =>
            page.items.map((item) => (
              <Grid
                item={true}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={item.id}
                id={item.id}
              >
                <Card>
                  <CardActionArea
                    className={classes.hoverEffect}
                    onClick={() => handleClick(item)}
                  >
                    <CardMedia image={item.url} className={classes.media} />
                    <IconButton
                      className={classes.downloadButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownload(item.url);
                      }}
                    >
                      <DownloadIcon htmlColor="white" />
                    </IconButton>
                    <IconButton
                      className={classes.deleteButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item.id);
                      }}
                    >
                      <DeleteIcon htmlColor="white" size="large" />
                    </IconButton>
                    {item.isNSFW && !props.nsfwMode ? (
                      <Box className={classes.blurOverlay}>
                        <IconButton>
                          <LockIcon htmlColor="white" size="large" />
                        </IconButton>
                        <Typography variant="h6" component="h5">
                          NSFW Content
                        </Typography>
                      </Box>
                    ) : (
                      <Box className={classes.cardContent}>
                        <Box display="flex">
                          <Typography
                            variant="h6"
                            component="h5"
                            gutterBottom={true}
                          >
                            {truncate(item.userPrompt, 30)}
                          </Typography>
                        </Box>
                        {item.userPrompt.length > 30 && (
                          <Typography
                            className={classes.cardSubtitle}
                            variant="caption"
                            component="h5"
                            gutterBottom={true}
                          >
                            {truncate(item.userPrompt, 100)}
                          </Typography>
                        )}
                        <CardActions className={classes.cardActions}>
                          <Button
                            endIcon={<StyleIcon htmlColor="white" />}
                            style={{ backgroundColor: "transparent" }}
                          >
                            {item.style}
                          </Button>
                          <Box>
                            <Button
                              edge="start"
                              className={classes.copyIcon}
                              endIcon={<CopyIcon />}
                              onClick={() => handleClone(item)}
                            >
                              Clone
                            </Button>
                            <Button
                              endIcon={
                                <FavoriteIcon
                                  color={likes[item.id] ? "primary" : "white"}
                                />
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLike(item.id);
                              }}
                            >
                              {Boolean(item.likesCount) +
                                (Boolean(likes[item.id]) -
                                  Boolean(initLikes[item.id]))}
                            </Button>
                          </Box>
                        </CardActions>
                      </Box>
                    )}
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          )}
      </Grid>
      <Grid
        container={true}
        justifyContent="center"
        spacing={4}
        style={{ marginTop: 60 }}
      >
        {isLoading && <Typography>Loading...</Typography>}
        {isError && <Typography>Error loadig the data</Typography>}
        {!isLoading && !isError && !hasNextPage && (
          <Typography>No {data && "more"} images</Typography>
        )}
      </Grid>
      {viewingItem && (
        <ItemModal
          item={viewingItemData}
          onLike={handleLike}
          onClone={handleClone}
          onDelete={handleDelete}
          likes={likes}
          initLikes={initLikes}
          onDone={() => setViewingItem(false)}
        ></ItemModal>
      )}
    </Container>
  );
}

export default Gallery;
