import React from "react";
import ChatPageUI from "../components/Chat.js/Chat";
import Meta from "../components/Meta";

const ChatPage = () => {
  return (
    <div>
      <Meta
        title="NSFW AI Chat: Free with No Limits"
        description="Discover the best NSFW AI Chat for unrestricted conversations. No filters, no limits! Start your NSFW chat for free today!"
        meta={{
          robots: "index",
        }}
      />
      <ChatPageUI />
    </div>
  );
};

export default ChatPage;
